import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#000000',
                accent: '#97d7d8'
            },
        },
    },
}

export default new Vuetify(opts);

/*
21118d
97d7d8
d0cde7
*/