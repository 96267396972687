<template>
  <v-app>
    <v-main class="app">
      <RouterView />
      <FooterNav />
    </v-main>
  </v-app>
</template>

<script>
import FooterNav from "@/components/shared/FooterNav";

export default {
  name: "App",
  components: { FooterNav },
};
</script>

<style lang="scss">
.app {
  background-color: #fff;
}
</style>
