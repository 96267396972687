import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import('../views/HomeView')
},
{
  path: '/patient',
  component: () => import('../views/PatientView')
},
{
  path: '/consultant',
  component: () => import('../views/ConsultantView')
},
{
  path: '/hospital',
  component: () => import('../views/HospitalView')
},
{
  path: '/terms-of-use',
  component: () => import('../views/TermsOfUseView')
}, {
  path: '/privacy-policy',
  component: () => import('../views/PrivacyPolicyView')
},
{
  path: '/contact',
  component: () => import('../views/ContactView')
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router